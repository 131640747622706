/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 ~ Copyright 2020 Adobe Systems Incorporated
 ~
 ~ Licensed under the Apache License, Version 2.0 (the "License");
 ~ you may not use this file except in compliance with the License.
 ~ You may obtain a copy of the License at
 ~
 ~     http://www.apache.org/licenses/LICENSE-2.0
 ~
 ~ Unless required by applicable law or agreed to in writing, software
 ~ distributed under the License is distributed on an "AS IS" BASIS,
 ~ WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 ~ See the License for the specific language governing permissions and
 ~ limitations under the License.
 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

import React from 'react'
import { EditableComponent } from '@adobe/aem-react-editable-components'
import {
  Chip as UIFoundationsChip,
  Text as UIFoundationsText,
  TextSectionHtmlTags
} from '@dcxwam/dcx-wam-ui-foundations'
import {
  getTextAlignmentMappedProp,
  getMarginBottomMappedProp
} from '../../../utils/props-mappings'
import ChipEditConfig from './ChipEditConfig'
import { ChipModelProps } from './types'
import { EditableComponentPropsType } from '../../../utils/prop-types'
import withComponentRefresh from '../../../utils/aem/editor/withComponentRefresh'

const Chip: React.FC<EditableComponentPropsType<ChipModelProps>> = withComponentRefresh(({
  ...props
}) => {
  const {
    label,
    textAlign,
    color,
    borderColor,
    background,
    marginBottom,
    id,
    ...rest
  } = props.model
  const editableProps = { ...props, model: { ...rest } }

  const configAtom = {
    id,
    label,
    color,
    borderColor,
    background
  }

  const configSection = {
    component: TextSectionHtmlTags.P,
    hasMarginBottom: getMarginBottomMappedProp(marginBottom),
    textHorizontalAlignment: getTextAlignmentMappedProp(textAlign)
  }

  return (
    <EditableComponent {...editableProps}>
      {label ? (
        <UIFoundationsText.TextSection {...configSection}>
          <UIFoundationsChip {...configAtom} />
        </UIFoundationsText.TextSection>
      ) : (
        ChipEditConfig.emptyLabel
      )}
    </EditableComponent>
  )
})

export default Chip
