import { getUserInfoLocalStorage } from './user-data-local-storage'
import { useUserData } from './user-data'
import { deepFlattenToObject } from '../../utils/flatten-object'

export const combinedUserObject = () => {
  const { user } = getUserInfoLocalStorage()
  const userData = useUserData()
  const userDataObject =
    userData && userData.user !== null && deepFlattenToObject(userData?.user)

  const combinedUserData = { ...user, ...userDataObject }

  return { userObject: combinedUserData }
}
