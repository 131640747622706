import { useEffect, useState } from 'react'
import { useUserData } from '../services/user/user-data'
import { MARKER_SUBREGEX } from './constants'

const getMarkerRegex = (replacementPattern: string) =>
  `(\\$${replacementPattern}_${MARKER_SUBREGEX})+`

interface AdaptationPatternModel {
  markerData: { [textKey: string]: string }
  data: { [textKey: string]: string }
  pattern: string
  isEditMode: boolean
}

const getAdaptedPatternValue = ({
  markerData,
  data,
  pattern,
  isEditMode = false
}: AdaptationPatternModel) => {
  if (!markerData) {
    return undefined
  }
  const updatedData = { ...markerData }
  Object.keys(markerData)?.forEach((dataKey: string) => {
    const markerValue = updatedData[dataKey]
    let replacedValue: string | undefined = markerValue
    if (markerValue?.includes(`$${pattern}_`)) {
      const regex = getMarkerRegex(pattern)
      const matchingWords = markerValue.match(new RegExp(regex, 'g'))
      if (matchingWords?.length) {
        matchingWords.forEach((matchingWord: string) => {
          const value = matchingWord.match(getMarkerRegex(pattern))?.[2]
          const replacement = data && value && data[value]
          replacedValue = replacedValue?.replace(
            matchingWord,
            replacement || (isEditMode ? matchingWord : '')
          )
        })
      }
    }
    updatedData[dataKey] = replacedValue
  })
  return updatedData
}

const getMappedMarkerData = (
  markerData: { [key: string]: string },
  isEditMode: boolean = false
) =>
  (isEditMode
    ? markerData
    : Object.keys(markerData)
        .map((key: string) => ({ [key]: '' }))
        .reduce(
          (accumulator, currentValue) =>
            Object.assign(accumulator, currentValue),
          {}
        ))

export const useDataMarkerReplace = (
  markerData: { [key: string]: string },
  pattern: string,
  isEditMode: boolean = false
): { [key: string]: string } => {
  const [values, setValues] = useState(
    getMappedMarkerData(markerData, isEditMode)
  )
  const userData = useUserData()

  useEffect(() => {
    const adaptedValue = getAdaptedPatternValue({
      markerData,
      data: userData?.user?.profile,
      pattern,
      isEditMode
    })
    // stop useEffect from being called in a loop if state didn't change
    if (
      JSON.stringify(values) !== JSON.stringify(adaptedValue) &&
      adaptedValue
    ) {
      setValues(adaptedValue)
    }
  }, [userData, markerData])

  return { ...values }
}
