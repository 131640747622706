import React, { useState, useEffect } from 'react'
import { EditableComponent } from '@adobe/aem-react-editable-components'
import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager'
import {
  ContainerWidths,
  Container as UIFoundationsContainer,
  StickyScreenPositions,
  Sticky as UIFoundationsSticky
} from '@dcxwam/dcx-wam-ui-foundations'
import { ROOT_ID } from '../../../utils/constants'
import Container from '../Container/Container'
import { EditableComponentPropsType } from '../../../utils/prop-types'
import withComponentRefresh from '../../../utils/aem/editor/withComponentRefresh'
import { useViewRefresh } from '../../../utils/use-view-refresh'

export interface StickyModelProps {
  id?: string
  observedComponentIds?: string[]
}

const Sticky: React.FC<EditableComponentPropsType<StickyModelProps>> =
  withComponentRefresh(({ ...props }) => {
    const { observedComponentIds, id, ...rest } = props.model
    const editableProps = { ...props, model: { ...rest } }
    const observedElMap = useViewRefresh(ROOT_ID, observedComponentIds)
    const [inViewMap, setInViewMap] = useState<{ [id: string]: boolean }>({})

    useEffect(() => {
      const defaultInViewMap =
        observedComponentIds
          ?.map((obsId: string) => ({ [obsId]: null }))
          .filter(Boolean)
          .reduce(
            (accumulator, currentValue) =>
              Object.assign(accumulator, currentValue),
            {}
          ) || {}
      setInViewMap(defaultInViewMap)
    }, [])

    useEffect(() => {
      const intersectionObserver = new IntersectionObserver(
        (entries: Array<IntersectionObserverEntry>) => {
          const filteredEntries = entries.filter((entry) => !!entry.rootBounds)
          if (filteredEntries.length > 0) {
            const newInViewMap = { ...inViewMap }
            let hasChanged = false
            filteredEntries.forEach((entry) => {
              if (entry.isIntersecting !== inViewMap[entry?.target?.id]) {
                hasChanged = true
                newInViewMap[entry?.target?.id] = entry.isIntersecting
              }
            })
            if (hasChanged) {
              setInViewMap(newInViewMap)
            }
          }
        }
      )
      Object.values(observedElMap).forEach((el) =>
        intersectionObserver?.observe(el)
      )
      return () => {
        intersectionObserver?.disconnect()
      }
    }, [inViewMap, observedElMap])

    const initializedInViewElementIds = Object.keys(inViewMap).filter(
      (elId) => inViewMap[elId] !== null
    )
    const noOneInView = !initializedInViewElementIds
      .map((elId) => inViewMap[elId])
      .some(Boolean)
    const isShown = initializedInViewElementIds.length > 0 && noOneInView
    const config = {
      isShown,
      screenPosition: StickyScreenPositions.Bottom,
      id
    }
    return (
      <EditableComponent {...editableProps}>
        {AuthoringUtils.isInEditor() ? (
          <Container {...editableProps} />
        ) : (
          <UIFoundationsSticky {...config}>
            <UIFoundationsContainer width={ContainerWidths.Full}>
              <Container {...editableProps} />
            </UIFoundationsContainer>
          </UIFoundationsSticky>
        )}
      </EditableComponent>
    )
  })

export default Sticky
