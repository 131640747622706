import React from 'react'
import { EditableComponent } from '@adobe/aem-react-editable-components'
import {
  Image as UIFoundationsImage,
  Text as UIFoundationsText,
  TextSectionHtmlTags
} from '@dcxwam/dcx-wam-ui-foundations'
import {
  getTextAlignmentMappedProp,
  getMarginBottomMappedProp,
  getImageMappedProps,
  ImageModel
} from '../../../utils/props-mappings'
import ImageEditConfig from './ImageEditConfig'
import { ImageModelProps } from './types'
import { EditableComponentPropsType } from '../../../utils/prop-types'
import withComponentRefresh from '../../../utils/aem/editor/withComponentRefresh'
import { getFormattedSrcset } from '../../../utils/get-formatted-srcset'

const Image: React.FC<EditableComponentPropsType<ImageModelProps>> =
  withComponentRefresh(({ ...props }) => {
    const {
      image,
      height: h,
      width: w,
      imageRenditions,
      ariaLabel,
      imageWidth,
      textAlign,
      srcset,
      marginBottom,
      id,
      dmImage,
      renditionWidths,
      renditionHeights,
      linkClass,
      ...rest
    } = props.model
    const editableProps = { ...props, model: { ...rest } }

    const configSection = {
      component: TextSectionHtmlTags.P,
      hasMarginBottom: getMarginBottomMappedProp(marginBottom),
      textHorizontalAlignment: getTextAlignmentMappedProp(textAlign)
    }

    const srcSetData = getFormattedSrcset(srcset ?? '')
    const imageProps = {
      ...(props.model as ImageModel),
      srcSetData
    }
    const configImageAtom = { ...getImageMappedProps(imageProps) }

    return (
      <EditableComponent {...editableProps}>
        {image?.src ? (
          <UIFoundationsText.TextSection {...configSection}>
            <UIFoundationsImage {...configImageAtom} />
          </UIFoundationsText.TextSection>
        ) : (
          ImageEditConfig.emptyLabel
        )}
      </EditableComponent>
    )
  })

export default Image
