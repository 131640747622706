/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { EditableComponent } from '@adobe/aem-react-editable-components'
import {
  TooltipDesignStyles,
  TooltipPlacements,
  Tooltip as UIFoundationsTooltip,
  Container as UIFoundationsContainer
} from '@dcxwam/dcx-wam-ui-foundations'
import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager'
import { EditableComponentPropsType } from '../../../utils/prop-types'
import Container from '../Container/Container'
import CopyToClipboardEditConfig, {
  CopyToClipboardTypes
} from './CopyToClipboardEditConfig'
import { canUseDom } from '../../../utils/isSsr'
import withComponentRefresh from '../../../utils/aem/editor/withComponentRefresh'

const CopyToClipboard: React.FC<
  EditableComponentPropsType<CopyToClipboardTypes>
> = withComponentRefresh(({ ...props }) => {
  const {
    copyElementId,
    copyFeedbackLabel,
    cqItems,
    cqItemsOrder,
    cqType,
    ...rest
  } = props?.model || {}

  const editableProps = { ...props, model: { ...rest } }

  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)

  const extentedItems = { ...cqItems }

  const handleCopyToClipboard = () => {
    if (copyElementId && canUseDom) {
      const textToCopy = document?.getElementById(copyElementId)?.textContent
      navigator.clipboard.writeText(textToCopy || '')
      setTooltipOpen(true)
    }
  }

  const handleTooltipClose = () => {
    setTooltipOpen(false)
  }

  Object.values(extentedItems).forEach((item: any) => {
    // eslint-disable-next-line no-param-reassign
    item.onClick = handleCopyToClipboard
  })

  const elementConfig = {
    model: {
      cqItems: extentedItems,
      cqItemsOrder,
      cqType,
      ...rest
    }
  }

  const tooltipConfig = {
    clickable: false,
    isOpen: tooltipOpen,
    title: copyFeedbackLabel,
    onClose: handleTooltipClose,
    designStyle: TooltipDesignStyles.Primary,
    placement: TooltipPlacements.TopStart
  }

  const CTCComponent = AuthoringUtils.isInEditor() ? (
    <Container {...editableProps} />
  ) : (
    <UIFoundationsContainer>
      <UIFoundationsTooltip {...tooltipConfig}>
        <Container {...elementConfig} />
      </UIFoundationsTooltip>
    </UIFoundationsContainer>
  )

  return (
    <EditableComponent {...props}>
      {!CopyToClipboardEditConfig.isEmpty(props)
        ? CTCComponent
        : CopyToClipboardEditConfig.emptyLabel}
    </EditableComponent>
  )
})

export default CopyToClipboard
