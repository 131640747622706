import React from 'react'
import {
  EditableComponent,
  ModelProps
} from '@adobe/aem-react-editable-components'
import { Accordion as UIFoundationsAccordion } from '@dcxwam/dcx-wam-ui-foundations'
import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager'
import Container from '../../templates/Container/Container'
import AccordionEditConfig from './AccordionEditConfig'
import { pushDataLayer } from '../../../utils/datalayer'
import { AccordionModelProps, CqItemModel } from './types'
import { EditableComponentPropsType } from '../../../utils/prop-types'
import withComponentRefresh from '../../../utils/aem/editor/withComponentRefresh'

const Accordion: React.FC<EditableComponentPropsType<AccordionModelProps>> = withComponentRefresh(({
  ...props
}) => {
  const { cqItems, id, ...rest } = props.model

  const editableProps = { ...props, model: { ...rest } }
  const accordionConfig = {
    items: Object.values(cqItems).map((item: CqItemModel) => {
      const accordionContent = {
        model: {
          ...item,
          cqItems: item[':items'],
          cqItemsOrder: item[':itemsOrder'],
          cqType: item[':type']
        }
      }
      return {
        titleProps: {
          title: item?.title || '',
          imageProps: {
            src: item?.imagePath,
            height: item?.imageHeight,
            width: item?.imageWidth,
            imageAlt: item?.imgAlt,
            imageSrcSetData: item?.imageSrcSet
          }
        },
        ...(item && item.gtmItem && item.gtmItem.length > 0
          ? {
              onToggle: (_event, expanded) => {
                if (expanded && item?.gtmItem) {
                  pushDataLayer(item?.gtmItem)
                }
              }
            }
          : {}),
        children: <Container model={{ ...accordionContent } as ModelProps} />
      }
    })
  }

  return (
    <EditableComponent {...editableProps}>
      {AccordionEditConfig.isEmpty(props)
        ? AccordionEditConfig.emptyLabel
        : null}
      {!AccordionEditConfig.isEmpty(props) &&
        (AuthoringUtils.isInEditor() ? (
          <Container {...props} />
        ) : (
          <UIFoundationsAccordion {...accordionConfig} />
        ))}
    </EditableComponent>
  )
})

export default Accordion
