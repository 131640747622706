import React from 'react'
import {
  Dialog as UIFoundationsDialog,
  DialogMaxWidthSizes,
  Iframe
} from '@dcxwam/dcx-wam-ui-foundations'
import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager'
import { VideoModalActionProps } from './VideoModalActionProps'

const VideoModal = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [iframeSrc, setIframeSrc] = React.useState('')

  const openIframeWithSrc = (url) => {
    setIframeSrc(url)
    setIsOpen(true)
  }

  VideoModalActionProps.actionProps.onClick = openIframeWithSrc
  const dialogProps = {
    id: 'video-dialog',
    fullWidth: true,
    fullScreen: true,
    dismissible: true,
    onClose: () => setIsOpen(false),
    zIndex: 1351, // used same as for sag
    maxWidth: DialogMaxWidthSizes.MD,
    dialogContentPadding: false
  }

  const iframeConfig = {
    url: iframeSrc,
    width: '100%',
    height: '100%',
    additionalParams: {
      allow:
        "sync-script 'none'; sync-xhr 'none'; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
      referrerpolicy: 'strict-origin-when-cross-origin',
      style: { display: 'block' }
    }
  }

  return (
    isOpen &&
    !AuthoringUtils.isInEditor() && (
      <UIFoundationsDialog
        {...{
          isOpen,
          ...dialogProps
        }}
      >
        <Iframe {...iframeConfig} />
      </UIFoundationsDialog>
    )
  )
}

export default VideoModal
