import React, { useState, useEffect } from 'react'
import { EditableComponent } from '@adobe/aem-react-editable-components'
import {
  Link as UIFoundationsLink,
  Text as UIFoundationsText,
  TextHorizontalAlignments,
  TextSectionHtmlTags,
  LinkIconPositions,
  LinkUnderlineOptions,
  LinkFontSizes
} from '@dcxwam/dcx-wam-ui-foundations'
import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager'
import Container from '../Container/Container'
import ReadMoreEditConfig from './ReadMoreEditConfig'
import { ReadMoreModelProps } from './types'
import { EditableComponentPropsType } from '../../../utils/prop-types'
import withComponentRefresh from '../../../utils/aem/editor/withComponentRefresh'

const ReadMore: React.FC<EditableComponentPropsType<ReadMoreModelProps>> = withComponentRefresh(({
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const {
    readLessLabel,
    readMoreLabel,
    readLessAriaLabel,
    readMoreAriaLabel,
    ...rest
  } = props.model
  const editableProps = { ...props, model: { ...rest } }

  useEffect(() => {
    if (AuthoringUtils.isInEditor()) {
      setIsVisible(true)
    }
  }, [])

  const handleToggle = () => {
    setIsVisible(!isVisible)
  }
  const label = isVisible ? readLessLabel || readMoreLabel : readMoreLabel
  const ariaLabel =
    label === readMoreLabel ? readMoreAriaLabel : readLessAriaLabel

  const uiLinkProps = {
    iconPosition: LinkIconPositions.Right,
    underline: LinkUnderlineOptions.Always,
    fontSize: LinkFontSizes.Body2,
    onClick: handleToggle,
    label,
    ...(ariaLabel ? { 'aria-label': ariaLabel } : {})
  }

  return (
    <EditableComponent {...editableProps}>
      {readMoreLabel ? (
        <>
          {(isVisible || AuthoringUtils.isInEditor()) && (
            <Container {...editableProps} />
          )}
          <UIFoundationsText.TextSection
            textHorizontalAlignment={TextHorizontalAlignments.Center}
            component={TextSectionHtmlTags.P}
          >
            <UIFoundationsLink {...uiLinkProps} />
          </UIFoundationsText.TextSection>
        </>
      ) : (
        ReadMoreEditConfig.emptyLabel
      )}
    </EditableComponent>
  )
})

export default ReadMore
