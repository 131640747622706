import React from 'react'
import { EditableComponent } from '@adobe/aem-react-editable-components'
import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager'
import { Accordion as UIFoundationsAccordion } from '@dcxwam/dcx-wam-ui-foundations'
import Container from '../../templates/Container/Container'
import AccordionItemEditConfig from './AccordionItemEditConfig'
import { AccordionItemModelProps } from './types'
import { EditableComponentPropsType } from '../../../utils/prop-types'
import withComponentRefresh from '../../../utils/aem/editor/withComponentRefresh'

const AccordionItem: React.FC<
  EditableComponentPropsType<AccordionItemModelProps>
> = withComponentRefresh(({ ...props }) => {
  const {
    id,
    title,
    imagePath,
    imageHeight,
    imageWidth,
    imgAlt,
    imageSrcSet,
    ...rest
  } = props.model
  const editableProps = { ...props, model: { ...rest } }
  const accordionConfig = {
    items: [
      {
        titleProps: {
          title: title || '',
          imageProps: {
            src: imagePath,
            height: imageHeight,
            width: imageWidth,
            imageAlt: imgAlt,
            imageSrcSetData: imageSrcSet
          }
        }
      }
    ]
  }
  return (
    <EditableComponent {...editableProps}>
      {AccordionItemEditConfig.isEmpty(props)
        ? AccordionItemEditConfig.emptyLabel
        : null}
      {!AccordionItemEditConfig.isEmpty(props) &&
        (AuthoringUtils.isInEditor() ? (
          <>
            <UIFoundationsAccordion {...accordionConfig} />
            <Container {...props} />
          </>
        ) : null)}
    </EditableComponent>
  )
})

export default AccordionItem
