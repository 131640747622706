import { useEffect, useState } from 'react'

export const useViewRefresh = (
  viewScopeId: string,
  observedElIds: Array<string>
) => {
  const [observedElMap, setObservedElMap] = useState<{
    [id: string]: HTMLElement | null
  }>({})

  useEffect(() => {
    const elMap =
      observedElIds
        ?.map((obsId) => {
          const el = document.getElementById(obsId)
          return el ? { [obsId]: el } : null
        })
        .filter(Boolean)
        .reduce(
          (accumulator, currentValue) =>
            Object.assign(accumulator, currentValue),
          {}
        ) || {}
    setObservedElMap(elMap)
  }, [observedElIds])

  const handleMutations = (mutations: MutationRecord[]) => {
    mutations?.forEach((mutation) => {
      if (mutation.type === 'childList') {
        const currentElMap: { [id: string]: HTMLElement | null } = {}
        Object.keys(observedElMap).forEach((elId: string) => {
          currentElMap[elId] = document.getElementById(elId)
        })
        const hasChanged =
          Object.keys(currentElMap).filter(
            (elId: string) => currentElMap[elId] !== observedElMap[elId]
          ).length > 0
        if (hasChanged) {
          setObservedElMap({ ...currentElMap })
        }
      }
    })
  }

  useEffect(() => {
    const mutationObserver = new MutationObserver(handleMutations)
    mutationObserver?.observe(
      document.getElementById(viewScopeId) as HTMLElement,
      {
        childList: true,
        attributes: true,
        subtree: true
      }
    )
    return () => {
      mutationObserver?.disconnect()
    }
  }, [observedElMap])

  return observedElMap
}
