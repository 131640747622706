import React, { useEffect, useId, useState } from 'react'
import { EditableComponent } from '@adobe/aem-react-editable-components'
import {
  Iframe as UIFoundationsIframe,
  Container as UIFoundationsContainer
} from '@dcxwam/dcx-wam-ui-foundations'
import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager'
import { useIsSsr } from '../../../utils/isSsr'
import { combinedUserObject } from '../../../services/user/combined-user-object'
import { EditableComponentPropsType } from '../../../utils/prop-types'
import { IframeModelProps } from './types'
import withComponentRefresh from '../../../utils/aem/editor/withComponentRefresh'

const constructSrc = (src: string, params: string) => {
  // case with already existing query params in url
  if (src?.indexOf('?') !== -1) {
    return src.indexOf('?') === src.length - 1
      ? src + params
      : `${src}&${params}`
  }
  return `${src}?${params}`
}

const Iframe: React.FC<EditableComponentPropsType<IframeModelProps>> =
  withComponentRefresh(({ ...props }): React.JSX.Element => {
    const {
      id,
      url,
      title,
      name,
      height,
      width,
      isAdaptedToContent,
      parameters,
      ...rest
    } = props.model
    const editableProps = { ...props, model: { ...rest } }
    const [iframeMutationObserver, setIframeMutationObserver] =
      useState<MutationObserver | null>(null)
    const adaptedId = useId()
    const isSsr = useIsSsr()
    const size = {
      ...(!isAdaptedToContent ? { height: height ?? '300' } : {}),
      width: width ?? '100%'
    }
    let comp = null
    const iframeId = id ?? adaptedId
    const { userObject } = combinedUserObject()

    useEffect(
      () => () => {
        iframeMutationObserver?.disconnect()
      },
      []
    )

    if (!isSsr) {
      const urlQueryParamsURL = parameters
        ?.filter((obj) => Object.keys(obj).length > 0 && obj.key !== undefined)
        .map((param) => {
          if (!param.value) {
            const userKey =
              userObject &&
              Object.keys(userObject).find((key) => key === param.key)
            if (userKey) {
              return `${param.key}=${userObject[userKey]}`
            }
            return param.key
          }
          return `${param.key}=${param.value}`
        })
      const urlValue =
        urlQueryParamsURL && url
          ? constructSrc(url, urlQueryParamsURL?.join('&'))
          : url
      const configI = {
        url: urlValue,
        id: iframeId,
        onLoad: () => {
          const iframe = document.getElementById(
            iframeId
          ) as HTMLIFrameElement | null
          if (iframe && iframe.contentDocument) {
            const iframeBody = iframe.contentDocument.body
            if (iframeBody) {
              const callback = (mutationsList: Array<MutationRecord>) => {
                mutationsList.filter(Boolean).forEach(() => {
                  iframe.style.height = `${iframeBody.scrollHeight}px`
                })
              }
              iframeBody.style.backgroundColor = 'transparent'
              if (isAdaptedToContent) {
                const observer = new MutationObserver(callback)
                const config = {
                  attributes: false,
                  childList: true,
                  subtree: true
                }
                observer.observe(iframeBody, config)
                setIframeMutationObserver(observer)
              }
            }
          }
        },
        title,
        ...size,
        name,
        additionalParams: {
          allow: "sync-script 'none'; sync-xhr 'none'",
          referrerpolicy: 'strict-origin-when-cross-origin',
          ...(AuthoringUtils.isInEditor()
            ? {}
            : { style: { ...size, display: 'block' } })
        }
      }
      comp = <UIFoundationsIframe {...configI} />
    } else {
      const containerConfig = {
        ...size,
        id
      }
      comp = <UIFoundationsContainer {...containerConfig} />
    }
    return <EditableComponent {...editableProps}>{comp}</EditableComponent>
  })

export default Iframe
