import { useState } from 'react'
import { singletonHook } from 'react-singleton-hook'

let layoutBottomShift: number = 0
let updateLayoutBottomShiftChangeSubscribers = (value: number) => {
  layoutBottomShift = value
}

export const getLayoutBottomShift = () => layoutBottomShift

export const setLayoutBottomShift = (value: number) => {
  layoutBottomShift = value
  updateLayoutBottomShiftChangeSubscribers(layoutBottomShift)
}

export const useInitLayoutBottomShift = singletonHook(
  getLayoutBottomShift,
  () => {
    const [bottomShift, setBottomShift] = useState<number>(getLayoutBottomShift)
    updateLayoutBottomShiftChangeSubscribers = setBottomShift
    return bottomShift
  }
)
