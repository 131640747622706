import React, { useMemo } from 'react'
import { useDcxChat } from '@dcxiqos/iqos-com-chat'
import {
  IconButton as UIIconButton,
  IconButtonRole,
  IconButtonSize
} from '@dcxwam/dcx-wam-ui-foundations'
import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager'
import { useCookies } from 'react-cookie'
import { AemConfigProxy } from '../AemConfigProxy/AemConfigProxy'
import { useUserData } from '../../../services/user/user-data'
import { ChatIconButtonActionProps } from './ChatIconButtonActionProps'
import { useInitLayoutBottomShift } from '../../../utils/app-layout'

const getConfig = (cookies, userData, aemChatConfig) => ({
  websiteOrigin: window?.location?.origin,
  userID: cookies?.gig_uid ? cookies.gig_uid : 'anonymous',
  friendlyName: userData?.friendlyName ? userData.friendlyName : 'anonymous',
  market: aemChatConfig?.market,
  language: aemChatConfig?.lang,
  flexFlowSid: aemChatConfig?.flexFlowSid,
  accountSid: aemChatConfig?.accountSid,
  runtimeDomain: aemChatConfig?.twilio?.runtimeDomain,
  noCache: true,
  enableLogs: true,
  containerOffset: '8em',
  useLiveChat: aemChatConfig?.liveChatEnabled,
  timezone: aemChatConfig?.timezone,
  twilioScript: aemChatConfig?.twilio.deploymentUrl
})

const Chat = ({ userData, aemChatConfig }) => {
  const [cookies] = useCookies()
  const bottomShift = useInitLayoutBottomShift()

  const config = useMemo(
    () => getConfig(cookies, userData, aemChatConfig),
    [userData]
  )

  try {
    const { onClickChatIcon, isEnabled, isLoading } = useDcxChat(config)
    ChatIconButtonActionProps.actionProps.onClick = onClickChatIcon
    const buttonConfig = {
      onClick: onClickChatIcon,
      iconButtonRole: IconButtonRole.Button,
      icon: 'pmi-icon-comment',
      size: IconButtonSize.Large,
      'aria-label': aemChatConfig?.ariaLabel,
      loading: isLoading,
      sx: {
        position: 'fixed',
        bottom: `calc(1rem + ${bottomShift || 0}px)`,
        insetInlineEnd: '1rem',
        zIndex: 900
      }
    }
    return isEnabled ? <UIIconButton {...buttonConfig} /> : null
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('ChatIconButton', error)
    return null
  }
}

const ChatIconButton = () => {
  const config = AemConfigProxy?.getConfig(AemConfigProxy?.ConfigObjects)
  const userData = useUserData()
  const friendlyName = [
    userData?.user?.profile?.firstName || '',
    userData?.user?.profile?.lastName || ''
  ]
    .filter(Boolean)
    .join(' ')
  const userName = friendlyName
    ? {
        friendlyName
      }
    : null
  const showChatButton =
    Object.keys(config).length !== 0 &&
    Object.keys(config?.chat || {}).length !== 0 &&
    userData !== undefined
  return !AuthoringUtils.isInEditor() && showChatButton ? (
    <Chat
      userData={userName}
      aemChatConfig={{
        market: config?.market,
        lang: config?.lang,
        ...{ ...config?.chat }
      }}
    />
  ) : null
}

export default ChatIconButton
