// input: {"profile": {"firstName": "FirstName","lastName": "LastName"} }
// output: {"profile_firstName": "FirstName", "profile_lastName": "LastName"}

export const deepFlattenToObject = (obj, prefix = '') =>
  Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? `${prefix}_` : ''
    if (typeof obj[k] === 'object' && obj[k] !== null) {
      Object.assign(acc, deepFlattenToObject(obj[k], pre + k))
    } else {
      acc[pre + k] = obj[k]
    }
    return acc
  }, {})
