/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 ~ Copyright 2020 Adobe Systems Incorporated
 ~
 ~ Licensed under the Apache License, Version 2.0 (the "License");
 ~ you may not use this file except in compliance with the License.
 ~ You may obtain a copy of the License at
 ~
 ~     http://www.apache.org/licenses/LICENSE-2.0
 ~
 ~ Unless required by applicable law or agreed to in writing, software
 ~ distributed under the License is distributed on an "AS IS" BASIS,
 ~ WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 ~ See the License for the specific language governing permissions and
 ~ limitations under the License.
 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

import React from 'react'
import { EditableComponent } from '@adobe/aem-react-editable-components'
import {
  Spacer as UIFoundationsSpacer,
  SpacerBorderColors,
  SpacerHeights,
  ThemeUtils
} from '@dcxwam/dcx-wam-ui-foundations'
import SpacerEditConfig from './SpacerEditConfig'
import { SpacerModelProps } from './types'
import { EditableComponentPropsType } from '../../../utils/prop-types'
import withComponentRefresh from '../../../utils/aem/editor/withComponentRefresh'

const Spacer: React.FC<EditableComponentPropsType<SpacerModelProps>> = withComponentRefresh(({
  ...props
}) => {
  const {
    id,
    xsHeight,
    smHeight,
    mdHeight,
    lgHeight,
    xlHeight,
    spacerBorderTopTypeVariant,
    spacerBackground,
    ...rest
  } = props.model
  const editableProps = { ...props, model: { ...rest } }

  const spacerHeightValues = [xsHeight, smHeight, mdHeight, lgHeight, xlHeight]

  const setSpacerHeightValue = (height?: string) => {
    switch (height) {
      case 'None':
        return SpacerHeights.None
      case '0':
        return SpacerHeights.Size_0
      case '1':
        return SpacerHeights.Size_1
      case '2':
        return SpacerHeights.Size_2
      case '3':
        return SpacerHeights.Size_3
      case '4':
        return SpacerHeights.Size_4
      case '5':
        return SpacerHeights.Size_5
      case '6':
        return SpacerHeights.Size_6
      case '7':
        return SpacerHeights.Size_7
      case '8':
        return SpacerHeights.Size_8
      case '9':
        return SpacerHeights.Size_9
      case '10':
        return SpacerHeights.Size_10
      default:
        break
    }
    return 'None'
  }

  let spacerBorderTopTypeVariantProp = SpacerBorderColors.None
  switch (spacerBorderTopTypeVariant) {
    case 'none':
      spacerBorderTopTypeVariantProp = SpacerBorderColors.None
      break
    case 'dark':
      spacerBorderTopTypeVariantProp = SpacerBorderColors.Dark
      break
    case 'light':
      spacerBorderTopTypeVariantProp = SpacerBorderColors.Light
      break
    default:
      break
  }

  spacerHeightValues.forEach((_element, index) => {
    spacerHeightValues[index] = setSpacerHeightValue(spacerHeightValues[index])
  })

  const uiProps = {
    id,
    spacerBorderTopTypeVariant: spacerBorderTopTypeVariantProp,
    spacerBackground,
    spacerHeight: ThemeUtils.getResponsiveProp(spacerHeightValues)
  }

  return (
    <EditableComponent {...editableProps}>
      {!SpacerEditConfig.isEmpty(props?.model) ? (
        // @ts-expect-error catch spacerHeight being undefined
        <UIFoundationsSpacer {...uiProps} />
      ) : (
        SpacerEditConfig.emptyLabel
      )}
    </EditableComponent>
  )
})

export default Spacer
