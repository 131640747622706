import React from 'react'
import { EditableComponent } from '@adobe/aem-react-editable-components'
import {
  Link as UIFoundationsLink,
  LinkIconPositions,
  LinkThemes,
  LinkUnderlineOptions,
  Text as UIFoundationsText,
  TextSectionHtmlTags,
  LinkFontSizes,
  LinkHyphens
} from '@dcxwam/dcx-wam-ui-foundations'
import {
  getTextAlignmentMappedProp,
  getMarginBottomMappedProp
} from '../../../utils/props-mappings'
import LinkEditConfig from './LinkEditConfig'
import { LinkModelProps } from './types'
import { EditableComponentPropsType } from '../../../utils/prop-types'
import withComponentRefresh from '../../../utils/aem/editor/withComponentRefresh'

const Link: React.FC<EditableComponentPropsType<LinkModelProps>> = withComponentRefresh(({
  ...props
}) => {
  const {
    id,
    label,
    ariaLabel,
    fontSize,
    textAlign,
    hyphenation,
    icon,
    iconPosition,
    linkTarget,
    linkUrl,
    additionalClasses,
    appliedCssClassNames,
    underline,
    marginBottom,
    onClick,
    ...rest
  } = props.model
  const editableProps = { ...props, model: { ...rest } }

  let linkIconPosition = LinkIconPositions.Left
  switch (iconPosition) {
    case 'DEFAULT':
      linkIconPosition = LinkIconPositions.Left
      break
    case 'END':
      linkIconPosition = LinkIconPositions.Right
      break
    default:
      break
  }

  let linkTheme = LinkThemes.Light
  switch (appliedCssClassNames) {
    case 'cmp-theme--light':
      linkTheme = LinkThemes.Light
      break
    case 'cmp-theme--dark':
      linkTheme = LinkThemes.Dark
      break
    default:
      break
  }

  let linkUnderline = LinkUnderlineOptions.Always
  switch (underline) {
    case 'always':
      linkUnderline = LinkUnderlineOptions.Always
      break
    case 'hover':
      linkUnderline = LinkUnderlineOptions.Hover
      break
    case 'none':
      linkUnderline = LinkUnderlineOptions.None
      break
    default:
      break
  }

  let linkFontSize = LinkFontSizes.Body1
  switch (fontSize) {
    case 'body1':
      linkFontSize = LinkFontSizes.Body1
      break
    case 'body2':
      linkFontSize = LinkFontSizes.Body2
      break
    case 'body3':
      linkFontSize = LinkFontSizes.Body3
      break
    case 'fs1':
      linkFontSize = LinkFontSizes.Fs1
      break
    case 'fs2':
      linkFontSize = LinkFontSizes.Fs2
      break
    case 'fs3':
      linkFontSize = LinkFontSizes.Fs3
      break
    case 'fs4':
      linkFontSize = LinkFontSizes.Fs4
      break
    case 'fs5':
      linkFontSize = LinkFontSizes.Fs5
      break
    case 'fs6':
      linkFontSize = LinkFontSizes.Fs6
      break
    case 'h1':
      linkFontSize = LinkFontSizes.H1
      break
    case 'h2':
      linkFontSize = LinkFontSizes.H2
      break
    case 'h3':
      linkFontSize = LinkFontSizes.H3
      break
    case 'h4':
      linkFontSize = LinkFontSizes.H4
      break
    case 'h5':
      linkFontSize = LinkFontSizes.H5
      break
    case 'h6':
      linkFontSize = LinkFontSizes.H6
      break
    default:
      break
  }
  const hyphens = hyphenation ? LinkHyphens.Auto : LinkHyphens.None

  const configAtom = {
    id,
    label,
    linkHorizontalAlignment: getTextAlignmentMappedProp(textAlign),
    'aria-label': ariaLabel,
    icon,
    iconPosition: linkIconPosition,
    linkTheme,
    linkUrl,
    linkTarget,
    hyphens,
    className: additionalClasses,
    fontSize: linkFontSize,
    underline: linkUnderline,
    onClick
  }

  const configSection = {
    component: TextSectionHtmlTags.P,
    hasMarginBottom: getMarginBottomMappedProp(marginBottom),
    textHorizontalAlignment: getTextAlignmentMappedProp(textAlign)
  }

  return (
    <EditableComponent {...editableProps}>
      {label && label.length > 0 ? (
        <UIFoundationsText.TextSection {...configSection}>
          <UIFoundationsLink {...configAtom} />
        </UIFoundationsText.TextSection>
      ) : (
        LinkEditConfig.emptyLabel
      )}
    </EditableComponent>
  )
})

export default Link
